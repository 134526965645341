import Handlebars from 'handlebars';
import { getToken } from './helper';

export var config = "";
export var M = "";
export const K = "WjGJYzSUN9jvIy24O2nNZzVHEyCrpzczH0DV2xiXphB";

// Inistail state of the config.
export const initialState = {
    acls: [],
    acl_fields: [],
    view: {
        sort: { field: "cm_modified", direction: "desc" },
        query: "",
        facets: ["path_starts_with"],
        columns: ["cm_name", "cm_title", "cm_modified"],
        filters: [],
        results_view: "grid",
        filter_rules: [],
    },
    summary_views: [],
    result_views: [],
    colour_rules: [],
    social_media: {
        facebook_filters: [],
        instagram_filters: []
    },
    media: { allow_social: false },
    title: "SchoolBench®",
    default_filter: "",
    filter_rules: {},
    path_strip: "/Company Home/",
    drop_folder: "/Company Home/Semabench",
    loaded: false,
    failed: false,
    suggested_searches: [],
    pipeline_names: {},
    has_watermark: false,
    enable_quick_parent: false,
    enable_community: false,
    enable_timeout: "false",
    action_timeout: "10",
    popup_timeout: "30"
};

// initial state of the info and default fields
const infoInitialState = {
    fields: {
        "path_starts_with": {
            title: "Path Starts With",
            type: "path",
            is_mandatory: false,
            is_multivalued: false
        },
        "type": {
            title: "Type",
            type: "text",
            is_mandatory: false,
            is_multivalued: false
        },
        "mime_type": {
            title: "Mime type",
            type: "text",
            is_mandatory: false,
            is_multivalued: false
        },
        "size": {
            title: "File size",
            type: "long",
            is_mandatory: false,
            is_multivalued: false
        },
        "preview_kind": {
            title: "Preview Kind",
            type: "text",
            is_mandatory: false,
            is_multivalued: true
        },
        "id": {
            title: "Node ID",
            type: "text",
            is_mandatory: false,
            is_multivalued: false
        }
    },
    // for provide the corresponding labels for fields name coming back from solr or Alfresco
    lookup: { "preview_kind": "Preview Kind", "path": "Path", "path_starts_with": "Path Starts With", "type": "Type", "size": "File size", "mime_type": "Mime type", "aspects": "Aspects", "site": "Site", "id": "Node ID", "[NOW-1DAY TO NOW]": "Last 24hrs", "[NOW-7DAYS TO NOW]": "Last 7 days", "[NOW-1MONTH TO NOW]": "Last 31 days", "[NOW-1YEAR TO NOW]": "Last 365 days", "[* TO NOW-1YEAR]": "More than a year ago" },
    error: {}
};


export var Field_Info = infoInitialState;

/**
 * Take a config and augment with any default values or adjustments that need to be made before
 * loading in the config
 */
const populateDefaultValues = (config) => {

    if (config.view.filter_rules === undefined || config.view.filter_rules === null) {
        config.view.filter_rules = populateFilterRules(config);
    }

    // Populate any missing keys from initialState (eg. user has upgraded and is missing a view key)
    Object.keys(initialState.view).forEach(key => {
        if (config.view[key] === undefined || config.view[key] === null) {
            config.view[key] = initialState[key];
        }
    });

    return config;
}

// populates the default filter rules if undefined
const populateFilterRules = (config) => {
    if (config.filter_rules !== undefined && config.filter_rules !== null) {
        return Object.keys(config.filter_rules);
    }
    return [];
}

// Loads the config from the server
export function loadConfig() {
    fetch("../api/v1/config", {
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
        .then((response) => response.json())
        .then(async (resp) => {
            config = resp.config;
            config.portal_valied = resp.portal_valied
            config.view.filter_rules = populateFilterRules(config);
            config = populateDefaultValues(config);
            let acls = [];
            let acl_fields = [];
            if (resp.acl !== undefined && resp.acl !== null) {
                // Goes through and sorts out all acls from config
                acls = resp.acl.map(acl => {

                    acls.roles = acl.roles.map(role => {
                        let transformedRole = role;

                        if (typeof role === 'string') {
                            let re = /{{[{]?(.*?)[}]?}}/g;
                            let match = null;
                            let is_template = false;

                            while ((match = re.exec(role))) {
                                is_template = true;
                                acl_fields.push(match[1]);
                            }

                            if (is_template) {
                                transformedRole = Handlebars.compile(role);
                            }
                        }

                        return transformedRole;
                    })



                    if (acl.params !== undefined && acl.params != null) {
                        if (acl.params.matched_values !== undefined && acl.params.matched_values !== null) {
                            Object.keys(acl.params.matched_values).forEach(val => acl_fields.push(val));
                        }
                    }

                    return acl;
                    // return role
                })

                config.acls = acls;
                config.acl_fields = acl_fields;
            }
            // Saves the config to local browser data that other parts will use
            let view = JSON.parse(localStorage.getItem("V"));
            if (view === null || view === undefined) {
                localStorage.setItem("V", JSON.stringify(config.view));
            }

            loadInfo(resp.fields);

        })
}


// Loads the config from the server
export function updateConfig(new_config) {
    config = new_config
}

function loadInfo(field_info) {
    if (field_info !== undefined && field_info !== null) {
        let lookup = Field_Info.lookup;
        let fields = field_info;

        if (fields["cm_content"]) {
            fields["cm_content"].is_multivalued = true;
        }

        Object.keys(fields).forEach((key) => {
            if (fields[key].title) {
                lookup[key] = fields[key].title;
            }
        });

        Field_Info.fields = {
            ...Field_Info.fields,
            ...fields
        }
        Field_Info.lookup = lookup;
    }

}

// Method called to get the form from the local storage
export const getForm = () => {
    let form = {
        "rulesets": [
            {
                "name": "default_node",
                "conditions": {
                    "required_fields": ["cm_content"],
                    "create_mode": true,
                    "initial_only": true
                },
                "templates": {
                    "path": "/Company Home/Schoolbench/{{substring id 0 2}}/{{substring id 2 4}}/",
                    "sb_adminStatus": "Unverified",
                    "type": "cm_content",
                    "cm_name": "{{{filename}}}"
                }
            },
            {
                "name": "profile",
                "conditions": {
                    "required_fields": ["sb_profileName"],
                    "create_mode": true,
                },
                "templates": {
                    "sb_adminStatus": "Profile"
                }
            }
        ]
    }
    return form;
}

export async function LT() {
    let token = getToken();
    return await fetch("../api/v1/m", {
        method: 'GET',
        headers: { "semabench-token": token }
    }).then(res => {
        return res.json()
    }).then(async result => {
        M = await DT(result);
        return M
    })
}

export async function T() {
    if (M === "") {
        return await LT()
    } else {
        return M
    }
}

export async function DT(encryptedInput) {
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();

    const ad = encoder.encode("semabench"); // Additional authenticated data

    // Decode the secret key from Base64 to bytes
    const secretKeyBytes = Uint8Array.from(atob(K), c => c.charCodeAt(0));

    // Ensure the key is 32 bytes (256 bits)
    if (secretKeyBytes.length !== 32) {
        throw new Error("Key must be exactly 256 bits (32 bytes) long.");
    }

    // Generate a key from the decoded secret key
    const key = await window.crypto.subtle.importKey(
        'raw',
        secretKeyBytes,
        { name: 'AES-GCM' },
        false,
        ['decrypt']
    );

    // Decode the base64-encoded input into bytes
    const encryptedBytes = Uint8Array.from(atob(encryptedInput), c => c.charCodeAt(0));

    // Extract the nonce (first 12 bytes) and ciphertext (remaining bytes)
    const nonce = encryptedBytes.slice(0, 12); // First 12 bytes for the nonce
    const ciphertext = encryptedBytes.slice(12); // Remaining bytes for the ciphertext

    // Perform AES-GCM decryption
    const decrypted = await window.crypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv: nonce,
            additionalData: ad,
            tagLength: 128,
        },
        key,
        ciphertext
    );

    // Decode decrypted data to a UTF-8 string
    let decryptedText = decoder.decode(decrypted);
    // console.log("Before cleaning:", decryptedText);

    // Remove null characters
    decryptedText = decryptedText.replace(/\u0000+$/g, ""); // Removes trailing null characters
    // console.log("After cleaning:", decryptedText);

    return decryptedText;
}
